// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Helpers???
import {
  withRequirements,
  userSignedInRequirement,
  userIsAdminRequirement,
} from "../../Requirements";
import { getCurrentUser } from "../../../firebase/authentication/auth";

// Styles
import "./BenchmarkingDashboard.css";

// Data Models
import { ComponentAnalysisStatus } from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import ComponentAnalysisType from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { DisclosurePackage } from "../../../model/DisclosureAnalysis/DisclosurePackage";
import * as API from "./API";
import * as Cell from "./ComponentCell";

// Buttons
import EyeView from "../../../assets/benchmark_eye.svg";

// WebSocket Setup
const WEBSOCKET_SSL_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "wss" : "ws";
const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

export async function getUserDisclosuresWebsocket() {
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();

  const wsUrl = `${WEBSOCKET_SSL_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/all/monitor/${user?.uid}?auth_token=${authToken}`;
  return new WebSocket(wsUrl);
}

// Main Components
function BenchmarkingDashboard(): JSX.Element {
  const [packages, setPackages] = useState<DisclosurePackage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [componentCellStatuses, setComponentCellStatuses] =
    useState<Cell.ComponentCellCorrectnessMap>({});
  const [benchmarkData, setBenchMarkData] =
    useState<API.DisclosureBenchmarkDataExistenceMap | null>(null);
  const [scoreResults, setScoreResults] = useState<Cell.ComponentTypeScoresMap>(
    {}
  );
  const navigate = useNavigate();

  async function userDisclosuresWebsocket(): Promise<WebSocket | null> {
    let ws = await getUserDisclosuresWebsocket();

    if (!ws) {
      navigate("/");
      return null;
    }

    ws.onopen = () => {
      console.debug("WebSocket opened");
    };

    ws.onmessage = async (event) => {
      const disclosurePackageResponse = JSON.parse(event.data);
      const sortedPackages = disclosurePackageResponse.disclosures.sort(
        (a: DisclosurePackage, b: DisclosurePackage) =>
          new Date(a.created).getTime() - new Date(b.created).getTime()
      );
      setPackages(sortedPackages);
      // setPackageBenchmarkingDataExistence(null);
      setBenchMarkData(null);
      setIsLoading(false);
    };

    ws.onerror = (error) => {
      console.error(error);
    };

    ws.onclose = () => {
      console.debug("WebSocket closed");
    };

    return ws; // Return the WebSocket instance
  }

  useEffect(() => {
    let ws: WebSocket | null = null;
    userDisclosuresWebsocket().then((webSocket) => {
      ws = webSocket;
    });
    return () => {
      if (ws) {
        console.debug("Closing WebSocket...");
        ws.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!packages.length) return;

    const calculateComponentTypeScore = async (
      componentType: ComponentAnalysisType,
      benchmarkData: API.DisclosureBenchmarkDataExistenceMap,
      correctnessMap: Cell.ComponentCellCorrectnessMap
    ): Promise<Cell.ComponentTypeScore> => {
      const relevantPackages = packages.filter((pkg) => {
        const component = Object.values(pkg.analysis?.components || {}).find(
          (comp) => comp.type === componentType
        );
        return (
          component &&
          component.status !== ComponentAnalysisStatus.FILES_NOT_DETECTED
        );
      });

      let correctnessResults: {
        key: string;
        correctness: API.ComponentBenchmarkingCorrectness;
      }[] = [];

      for (const pkg of relevantPackages) {
        const keyString: string = `${pkg.id}-${componentType}`;
        correctnessResults.push({
          key: `${pkg.id}-${componentType}`,
          correctness: correctnessMap[keyString],
        });
      }

      const stats = correctnessResults.reduce(
        (acc, result) => {
          if (!result) return { ...acc, isIncomplete: true };
          if (
            result.correctness === API.ComponentBenchmarkingCorrectness.TBD ||
            result.correctness === undefined
          )
            return acc;
          return {
            ...acc,
            totalFiles: acc.totalFiles + 1,
            correctFiles:
              result.correctness ===
              API.ComponentBenchmarkingCorrectness.CORRECT
                ? acc.correctFiles + 1
                : acc.correctFiles,
          };
        },
        { totalFiles: 0, correctFiles: 0, isIncomplete: false }
      );

      return formatScore(stats);
    };

    const formatScore = ({
      totalFiles,
      correctFiles,
      isIncomplete,
    }: {
      totalFiles: number;
      correctFiles: number;
      isIncomplete: boolean;
    }): Cell.ComponentTypeScore => {
      if (totalFiles === 0) {
        return { score: " ", scoreClass: "admin-analysis__score--none" };
      }

      const score = isIncomplete
        ? `x / ${totalFiles}`
        : `${correctFiles} / ${totalFiles}`;

      const scoreClass =
        correctFiles === totalFiles
          ? "admin-analysis__score--correct"
          : "admin-analysis__score--incorrect";

      return { score, scoreClass };
    };

    const updateAnalysis = async () => {
      // Check for package existences
      const benchmarkData = await API.userCheckPackagesExistence(
        packages[0].uid
      );
      setBenchMarkData(benchmarkData);
      if (benchmarkData === null) {
        return;
      }

      // Calculate all component scores in parallel
      const componentTypes = Object.values(ComponentAnalysisType);

      // Get scores from backend
      const correctnessMap = await API.getUserBenchmarkDataScores(
        packages[0].uid
      );

      const scores = await // Calculate column socres for all component types
      Promise.all(
        componentTypes.map(async (componentType) => ({
          componentType,
          result: await calculateComponentTypeScore(
            componentType,
            benchmarkData,
            correctnessMap
          ),
        }))
      );

      // Update state
      setScoreResults(
        scores.reduce((acc, { componentType, result }) => {
          acc[componentType] = result;
          return acc;
        }, {} as Cell.ComponentTypeScoresMap)
      );

      setComponentCellStatuses(correctnessMap);
    };

    updateAnalysis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  if (benchmarkData == null) {
    return (
      <div className="admin-analysis">
        <div style={{ fontSize: "44px" }}>Loading...</div>
        <div>Should take no more than 30 sec</div>
      </div>
    );
  }

  const uid = packages[0].uid;

  return (
    <div className="admin-analysis">
      <header>
        <h1 className="admin-analysis__title">Analysis Benchmark Tool</h1>
      </header>

      <section>
        <div className="admin-analysis__table-container">
          <table className="admin-analysis__table">
            <thead>
              <tr>
                <th className="admin-analysis__header admin-analysis__header--empty"></th>
                {Object.values(ComponentAnalysisType).map((componentType) => (
                  <Cell.HeaderCell
                    key={componentType}
                    componentType={componentType}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    uid={uid}
                    scoreResults={scoreResults}
                  />
                ))}
              </tr>
            </thead>

            <tbody>
              {packages.map((pkg, rowIndex) => (
                <tr key={rowIndex} className="admin-analysis__row">
                  <td className="admin-analysis__property">
                    <div className="admin-analysis__property-content">
                      <span className="admin-analysis__property-name">
                        {pkg.property_name}
                      </span>
                      <button className="admin-analysis__view-btn">
                        <img src={EyeView} alt="View Details" />
                      </button>
                    </div>

                    <Cell.PackageCell
                      benchmarkData={benchmarkData}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      pkg={pkg}
                    />
                  </td>

                  {Object.values(ComponentAnalysisType).map(
                    (componentType, colIndex) => (
                      <Cell.TableCell
                        pkg={pkg}
                        componentType={componentType}
                        cellStatuses={componentCellStatuses}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        colIndex={colIndex}
                      />
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default withRequirements(BenchmarkingDashboard, [
  userSignedInRequirement,
  userIsAdminRequirement,
]);
