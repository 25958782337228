import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../../firebase/authentication/auth";
import { ComponentViewerSelector } from "../DisclosureAnalysis/DisclosureViewer/AnalysisModuleViewer/ComponentViewer/ComponentViewerSelector";
import {
  checkPackageExistece,
  getComponentBenchmarkingCorrectness,
} from "../BenchmarkingDashboard/API";
import ComponentAnalysisType from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { DisclosurePackage } from "../../../model/DisclosureAnalysis/DisclosurePackage";
import {
  ComponentAnalysisGeneric,
  ComponentAnalysisModule,
  ComponentAnalysisStatus,
} from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import {
  withRequirements,
  userSignedInRequirement,
  userIsAdminRequirement,
} from "../../Requirements";
import PDFViewer from "../DisclosureAnalysis/DisclosureViewer/PDFViewer";

import {
  ComponentBenchmarkingCorrectness,
  setComponentBenchmarkingCorrectness,
  getGroundTruth,
  editGroundTruth,
  getNotes,
  editNotes,
} from "../BenchmarkingDashboard/API";

import "./BenchmarkingScoring.css";
import { PDFFile } from "../../../model/DisclosureAnalysis/PDFFile";
import { ClassifiedFile } from "../../../model/DisclosureAnalysis/ClassifiedFile";
import { fetchPdfFile } from "../DisclosureAnalysis/api";
import { SelectedPage } from "../DisclosureAnalysis/DisclosureViewer/PDFViewer/PDFViewer";

const WEBSOCKET_SSL_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "wss" : "ws";
const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

function BenchmarkingScoring() {
  // Router hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Component management
  const [packages, setPackages] = useState<DisclosurePackage[]>([]);
  const [components, setComponents] = useState<
    ComponentAnalysisModule<ComponentAnalysisGeneric>[]
  >([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Ground truth management
  const [groundTruth, setGroundTruth] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState("");

  // PDF Files
  const [pdfFilesToRender, setPdfFilesToRender] = useState<PDFFile[]>([]);
  const [selectedPage, setSelectedPage] = useState<SelectedPage | null>(null);

  // Notes management
  const [notes, setNotes] = useState<string>("");
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [editNotesValue, setEditNotesValue] = useState("");

  const [correctness, setCorrectness] =
    useState<ComponentBenchmarkingCorrectness | null>(null);

  // Retrieve component type and disclosureID from url
  const componentType = searchParams.get("type") as ComponentAnalysisType;
  const specifiedDisclosureID = searchParams.get("disclosure") as string | null;

  // Current component and package tracking
  const currentComponent = components[currentIndex];
  const currentPackage = currentComponent
    ? packages.find((pkg) =>
        Object.values(pkg.analysis.components).includes(currentComponent)
      )
    : null;

  // Navigation handlers
  const handleNext = () =>
    currentIndex < components.length - 1 && setCurrentIndex(currentIndex + 1);
  const handlePrevious = () =>
    currentIndex > 0 && setCurrentIndex(currentIndex - 1);

  const convertClassifiedFiletoPDFFile = useCallback(
    async (classifiedFiles?: ClassifiedFile[]): Promise<PDFFile[]> => {
      if (classifiedFiles && classifiedFiles.length > 0) {
        const pdfs = await Promise.all(
          classifiedFiles.map(async (file) => {
            const fetchedPDF = await fetchPdfFile(file.pdf);
            return fetchedPDF;
          })
        );
        return pdfs;
      }
      return [];
    },
    []
  );

  // Initialize websocket connection and handle cleanup
  useEffect(() => {
    // Websocket setup function
    async function setupWebsocket(): Promise<WebSocket | null> {
      try {
        const user = await getCurrentUser();
        const authToken = await user?.getIdToken();

        if (!user?.uid || !authToken) {
          console.error("No user or auth token available");
          navigate("/");
          return null;
        }

        const wsUrl = `${WEBSOCKET_SSL_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/all/monitor/${user.uid}?auth_token=${authToken}`;
        const ws = new WebSocket(wsUrl);

        ws.onopen = () => console.debug("WebSocket opened");

        ws.onmessage = (event) => {
          try {
            const disclosurePackageResponse = JSON.parse(event.data);
            const sortedPackages = disclosurePackageResponse.disclosures.sort(
              (a: DisclosurePackage, b: DisclosurePackage) =>
                new Date(a.created).getTime() - new Date(b.created).getTime()
            );
            setPackages(sortedPackages);
            setIsLoading(false);
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        ws.onerror = (error) => console.error("WebSocket error:", error);
        ws.onclose = () => console.debug("WebSocket closed");

        return ws;
      } catch (error) {
        console.error("Error setting up WebSocket:", error);
        return null;
      }
    }

    let ws: WebSocket | null = null;
    setupWebsocket().then((webSocket) => {
      ws = webSocket;
    });
    return () => ws?.close();
  }, [navigate]);

  // Components processing effect
  useEffect(() => {
    if (!packages.length) return;

    const getComponents = async () => {
      const updatedComponents: ComponentAnalysisModule<ComponentAnalysisGeneric>[] =
        [];

      for (const pkg of packages) {
        try {
          const benchmarkingExists = await checkPackageExistece(
            pkg.uid,
            pkg.analysis.disclosure_id
          );
          if (benchmarkingExists) {
            const matchingComponents = Object.values(
              pkg.analysis.components
            ).filter(
              (component) =>
                component.type === componentType &&
                (component.status === ComponentAnalysisStatus.COMPLETE ||
                  component.status === ComponentAnalysisStatus.ERROR) &&
                (specifiedDisclosureID === null ||
                  pkg.id === specifiedDisclosureID)
            ) as ComponentAnalysisModule<ComponentAnalysisGeneric>[];
            updatedComponents.push(...matchingComponents);
          }
        } catch (error) {
          console.error(
            `Error processing package ${pkg.property_name}:`,
            error
          );
        }
      }

      setComponents(updatedComponents);
    };

    getComponents();
  }, [packages, componentType, specifiedDisclosureID]);

  // Load ground truth, notes, and correctness
  useEffect(() => {
    setCorrectness(null);
    setPdfFilesToRender([]);
    setSelectedPage(null);

    if (currentComponent && currentPackage) {
      const loadData = async () => {
        // Get PDFs to render
        const pdfs = await convertClassifiedFiletoPDFFile(
          currentComponent?.associated_files
        );
        setPdfFilesToRender(pdfs);

        // Load ground truth
        const truth = await getGroundTruth(
          currentPackage.uid,
          currentPackage.analysis.disclosure_id,
          componentType
        );
        setGroundTruth(truth || "");
        setEditValue(truth || "");

        // Load notes
        const savedNotes = await getNotes(
          currentPackage.uid,
          currentPackage.analysis.disclosure_id,
          componentType
        );
        setNotes(savedNotes || "");
        setEditNotesValue(savedNotes || "");

        // Load correctness
        const correctness = await getComponentBenchmarkingCorrectness(
          currentPackage.uid,
          currentPackage.analysis.disclosure_id,
          componentType
        );
        setCorrectness(correctness);
      };

      loadData();
    }
  }, [
    currentComponent,
    currentPackage,
    componentType,
    convertClassifiedFiletoPDFFile,
  ]);

  // Handle ground truth editing
  const handleEditSubmit = async () => {
    if (currentPackage && editValue !== groundTruth) {
      await editGroundTruth(
        currentPackage.uid,
        currentPackage.analysis.disclosure_id,
        componentType,
        editValue
      );
      setGroundTruth(editValue);
    }
    setIsEditing(false);
  };

  // Handle notes editing
  const handleNotesSubmit = async () => {
    if (currentPackage && editNotesValue !== notes) {
      await editNotes(
        currentPackage.uid,
        currentPackage.analysis.disclosure_id,
        componentType,
        editNotesValue
      );
      setNotes(editNotesValue);
    }
    setIsEditingNotes(false);
  };

  // Handle correctness setting
  const handleCorrectnessChange = async (
    value: ComponentBenchmarkingCorrectness
  ) => {
    if (currentPackage) {
      await setComponentBenchmarkingCorrectness(
        currentPackage.uid,
        currentPackage.analysis.disclosure_id,
        componentType,
        value
      );
      setCorrectness(value);
    }
  };

  // Loading state render
  if (isLoading || !components.length) {
    return (
      <div className="admin-score">
        <div className="loading">Loading components...</div>
      </div>
    );
  }

  return (
    <div className="admin-score">
      <header>
        <div className="admin-score__title">Scoring</div>
        <div className="admin-score__navigate">
          <button onClick={handlePrevious} disabled={currentIndex === 0}>
            {`<`}
          </button>

          <div className="admin-score-page">
            Document: {`${currentIndex + 1}/${components.length}`}
          </div>

          <button
            onClick={handleNext}
            disabled={currentIndex === components.length - 1}
          >
            {`>`}
          </button>
        </div>
        <div className="admin-score__subtext">
          <h2 className="admin-score__subtext--property">
            Property: {currentPackage?.property_name || "Loading..."}
          </h2>

          <h2 className="admin-score__subtext--component-type">
            Component Type: {componentType}
          </h2>
        </div>
      </header>

      <section>
        <div className="admin-score-content">
          {/* Panel 1/3: Analysis output */}
          <div className="admin-score-content__component-viewer">
            <div>
              Component Status:{" "}
              <span
                style={{
                  color:
                    currentComponent.status === ComponentAnalysisStatus.ERROR
                      ? "red"
                      : "",
                  fontWeight: "bold",
                }}
              >
                {currentComponent.status}
              </span>
            </div>
            <br />
            {currentComponent && (
              <ComponentViewerSelector<ComponentAnalysisGeneric>
                component={currentComponent}
                setSelectedPage={setSelectedPage}
              />
            )}
          </div>

          <div className="admin-score-content__pdf-viewer">
            <PDFViewer
              pdfs={pdfFilesToRender}
              selected={selectedPage}
              height={480}
              width={240}
            />
          </div>

          {/* Panel 3/3: Ground truth / notes */}
          <div className="admin-score-content__right">
            <div className="admin-score-content__ground_truth">
              <div className="ground-truth-title">GROUND TRUTH</div>
              <div className="ground-truth-container">
                <textarea
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  disabled={!isEditing}
                  className="ground-truth-textarea"
                />

                <div className="editor-buttons">
                  {isEditing ? (
                    <div>
                      <button
                        className="ground-truth-btns ground-truth-btns--grouped"
                        onClick={handleEditSubmit}
                      >
                        Save
                      </button>
                      <button
                        className="ground-truth-btns ground-truth-btns--grouped"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="ground-truth-btns"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="notes-section">
              <div className="notes-title">NOTES</div>
              <div className="notes-container">
                <textarea
                  value={editNotesValue}
                  onChange={(e) => setEditNotesValue(e.target.value)}
                  disabled={!isEditingNotes}
                  className="notes-textarea"
                />

                <div className="editor-buttons">
                  {isEditingNotes ? (
                    <div>
                      <button
                        className="ground-truth-btns ground-truth-btns--grouped"
                        onClick={handleNotesSubmit}
                      >
                        Save
                      </button>
                      <button
                        className="ground-truth-btns ground-truth-btns--grouped"
                        onClick={() => setIsEditingNotes(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="ground-truth-btns"
                        onClick={() => setIsEditingNotes(true)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="correctness-controls">
          <div className="correctness-control__btns">
            <button
              className={`correct-button ${
                correctness === ComponentBenchmarkingCorrectness.CORRECT
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleCorrectnessChange(
                  ComponentBenchmarkingCorrectness.CORRECT
                )
              }
            >
              CORRECT
            </button>

            <button
              className={`incorrect-button ${
                correctness === ComponentBenchmarkingCorrectness.INCORRECT
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleCorrectnessChange(
                  ComponentBenchmarkingCorrectness.INCORRECT
                )
              }
            >
              INCORRECT
            </button>

            <button
              className={`tbd-button ${
                correctness === ComponentBenchmarkingCorrectness.TBD
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleCorrectnessChange(ComponentBenchmarkingCorrectness.TBD)
              }
            >
              TBD
            </button>
          </div>

          <h3 className={`correctness-control__text--${correctness}`}>
            {correctness === null ? (
              <div className="mini-spinner"></div>
            ) : (
              <span>Status: {correctness}</span>
            )}
          </h3>
        </div>
      </section>
    </div>
  );
}

export default withRequirements(BenchmarkingScoring, [
  userSignedInRequirement,
  userIsAdminRequirement,
]);
