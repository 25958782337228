// React
import React from "react";
import { useNavigate } from "react-router-dom";

import "./BenchmarkingDashboard.css";

// Data Models
import { ComponentAnalysisStatus } from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import ComponentAnalysisType, {
  ComponentTypeDisplayMapping,
} from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { DisclosurePackage } from "../../../model/DisclosureAnalysis/DisclosurePackage";
import * as API from "./API";

// Buttons
import ResetTradOCR from "../../../assets/reset_trad_ocr.svg";
import ResetFormOCR from "../../../assets/reset_form_ocr.svg";
import Reanalyze from "../../../assets/reanalyze.svg";
import ViewScore from "../../../assets/view_score.svg";
import ResetPackageTradOCR from "../../../assets/reset_package_trad_ocr.svg";
import ResetPackageFormOCR from "../../../assets/reset_package_form_ocr.svg";
import Analyze from "../../../assets/analyze.svg";
import ViewScorePackage from "../../../assets/view_score_package.svg";

import ResetCellTradOCR from "../../../assets/reset_cell_trad_ocr.svg";
import ResetCellFormOCR from "../../../assets/reset_cell_form_ocr.svg";
import ResetCellHTMLOCR from "../../../assets/reset_cell_html_ocr.svg";

import ViewCellTradOCR from "../../../assets/view_cell_trad_ocr.svg";
import ViewCellFormOCR from "../../../assets/view_cell_form_ocr.svg";
import ViewCellHTMLOCR from "../../../assets/view_cell_html_ocr.svg";

// Types
export interface ComponentCellCorrectnessMap {
  [key: string]: API.ComponentBenchmarkingCorrectness;
}

export interface ComponentTypeScore {
  score: string;
  scoreClass: string;
}

export interface ComponentTypeScoresMap {
  [key: string]: ComponentTypeScore; // mapping from component types to their column score results
}

// Props
interface HeaderCellProps {
  componentType: ComponentAnalysisType;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  uid: string;
  scoreResults: ComponentTypeScoresMap;
}

interface PackageCellProps {
  benchmarkData: API.DisclosureBenchmarkDataExistenceMap | null;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  pkg: DisclosurePackage;
}

interface TableCellProps {
  pkg: DisclosurePackage;
  componentType: ComponentAnalysisType;
  cellStatuses: { [key: string]: API.ComponentBenchmarkingCorrectness };
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  colIndex: number;
}

// Components

// Action Button Component
export const ActionButton: React.FC<{
  onClick: () => void | Promise<void>;
  disabled: boolean;
  icon: string;
  alt: string;
}> = ({ onClick, disabled, icon, alt }) => (
  <button
    className="admin-analysis__action-btn"
    onClick={onClick}
    disabled={disabled}
  >
    <img src={icon} alt={alt} />
  </button>
);

// Column Header Cell Component
export const HeaderCell: React.FC<HeaderCellProps> = ({
  componentType,
  isLoading,
  setIsLoading,
  uid,
  scoreResults,
}) => {
  const navigate = useNavigate();
  const scoreData = scoreResults[componentType];
  const formattedType = componentType.replace(/_/g, " ").toLowerCase();

  // Loading
  if (!scoreData) {
    return (
      <td className="admin-analysis__header">
        <div className="admin-analysis__header-content">
          <div className="admin-analysis__header-top">
            <span className="admin-analysis__category-name">
              {
                ComponentTypeDisplayMapping[
                  formattedType.toUpperCase() as ComponentAnalysisType
                ]
              }
            </span>
            <span
              className={`admin-analysis__score admin-analysis__score--none`}
            >
              N/A
            </span>
          </div>
          <div className="admin-analysis__actions">
            <ActionButton
              onClick={() => {
                alert("Can't perform while still loading.");
              }}
              disabled={isLoading}
              icon={ResetTradOCR}
              alt="Reset Trad OCR"
            />

            <ActionButton
              onClick={() => {
                alert("Can't perform while still loading.");
              }}
              disabled={isLoading}
              icon={ResetFormOCR}
              alt="Reset Form OCR"
            />

            <ActionButton
              onClick={() => {
                alert("Can't perform while still loading.");
              }}
              disabled={isLoading}
              icon={Reanalyze}
              alt="Reanalyze"
            />

            <ActionButton
              onClick={() => {
                alert("Can't perform while still loading.");
              }}
              disabled={isLoading}
              icon={ViewScore}
              alt="View Score"
            />
          </div>
        </div>
      </td>
    );
  }

  const { score, scoreClass } = scoreData;

  const handleAction = async (
    action: (uid: string, type: ComponentAnalysisType) => Promise<void>
  ) => {
    setIsLoading(true);
    try {
      await action(uid, componentType);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <td className="admin-analysis__header">
      <div className="admin-analysis__header-content">
        <div className="admin-analysis__header-top">
          <span className="admin-analysis__category-name">
            {
              ComponentTypeDisplayMapping[
                formattedType.toUpperCase() as ComponentAnalysisType
              ]
            }
          </span>
          <span className={`admin-analysis__score ${scoreClass}`}>
            {score === " " ? "N/A" : score}
          </span>
        </div>
        <div className="admin-analysis__actions"></div>
      </div>

      <div className="admin-analysis__actions">
        <ActionButton
          onClick={() => handleAction(API.resetComponentTypeTradOCR)}
          disabled={isLoading}
          icon={ResetTradOCR}
          alt="Reset Trad OCR"
        />

        <ActionButton
          onClick={() => handleAction(API.resetComponentTypeFormOCR)}
          disabled={isLoading}
          icon={ResetFormOCR}
          alt="Reset Form OCR"
        />

        <ActionButton
          onClick={() => handleAction(API.analyzeComponentType)}
          disabled={isLoading}
          icon={Reanalyze}
          alt="Reanalyze"
        />

        <ActionButton
          onClick={() => {
            navigate(`/admin/benchmarking/score?type=${componentType}`);
          }}
          disabled={isLoading}
          icon={ViewScore}
          alt="View Score"
        />
      </div>
    </td>
  );
};

// Package Cell Components
export const PackageCell: React.FC<PackageCellProps> = ({
  benchmarkData,
  isLoading,
  setIsLoading,
  pkg,
}) => {
  const handlePackageCreation = async (uid: string, disclosureId: string) => {
    const success = await API.createPackage(uid, disclosureId);
    if (success) {
      window.location.reload(); // Reload the page only if the request is successful
    }
  };

  if (benchmarkData === null || !benchmarkData[pkg.id]) {
    return (
      <button
        style={{ border: "1px solid black" }}
        onClick={() =>
          handlePackageCreation(pkg.uid, pkg.analysis?.disclosure_id)
        }
      >
        Init
      </button>
    );
  }

  const uid = pkg?.uid;
  const disclosureId = pkg.analysis.disclosure_id;

  const handleAction = async (
    action: (uid: string, disclosureId: string) => Promise<void>
  ) => {
    setIsLoading(true);
    try {
      await action(uid, disclosureId);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-analysis__property-actions">
      <ActionButton
        onClick={() => handleAction(API.resetTradOCR)}
        disabled={isLoading}
        icon={ResetPackageTradOCR}
        alt="View Score"
      />

      <ActionButton
        onClick={() => handleAction(API.resetFormOCR)}
        disabled={isLoading}
        icon={ResetPackageFormOCR}
        alt="Reset Package Score"
      />

      <ActionButton
        onClick={() => handleAction(API.analyzePackage)}
        disabled={isLoading}
        icon={Analyze}
        alt="Analyze Package"
      />

      <ActionButton
        onClick={() => {
          setIsLoading(true);
          alert("Not implemented yet.");
          setIsLoading(false);
        }}
        disabled={isLoading}
        icon={ViewScorePackage}
        alt="Reset Package Score"
      />
    </div>
  );
};

// Cell Components
export const TableCell: React.FC<TableCellProps> = ({
  pkg,
  componentType,
  cellStatuses,
  isLoading,
  setIsLoading,
  colIndex,
}) => {
  const component = Object.values(pkg.analysis?.components || {}).find(
    (comp) => comp.type === componentType
  );
  const navigate = useNavigate();

  const status = component?.status || ComponentAnalysisStatus.UNINITIATED;
  const isProcessing =
    status === ComponentAnalysisStatus.FILECLASSIFYING ||
    status === ComponentAnalysisStatus.ANALYZING;
  const cellCorrectness = cellStatuses[`${pkg.id}-${componentType}`];

  // If cell correctness hasn't been fetched
  if (Object.keys(cellStatuses).length === 0) {
    return (
      <td
        key={colIndex}
        className={`admin-analysis__cell admin-analysis__cell--loading`}
      >
        <div className="admin-analysis__status">Loading</div>
      </td>
    );
  }

  // If cell correctness doesn't exist (due to package benchmark data nonexistent)
  if (cellCorrectness === undefined) {
    return (
      <td
        key={colIndex}
        className={`admin-analysis__cell admin-analysis__cell--requires-init`}
      >
        <div className="admin-analysis__status">Requires Init</div>
      </td>
    );
  }

  // No files detected
  if (status === ComponentAnalysisStatus.FILES_NOT_DETECTED) {
    return (
      <td
        key={colIndex}
        className="admin-analysis__cell admin-analysis__cell--files_not_detected"
      >
        <div className="files_not_detected">No Files Detected</div>
      </td>
    );
  }

  // Determine cell status
  const adminCellClass = (() => {
    switch (cellCorrectness) {
      case API.ComponentBenchmarkingCorrectness.CORRECT:
        return "admin-analysis__cell--correct";
      case API.ComponentBenchmarkingCorrectness.INCORRECT:
        return "admin-analysis__cell--incorrect";
      case API.ComponentBenchmarkingCorrectness.TBD:
        return "admin-analysis__cell--tbd";
      default:
        return "admin-analysis__cell--requires-init";
    }
  })();

  const isButtonDisabled =
    isProcessing || status === ComponentAnalysisStatus.UNINITIATED || isLoading;

  const formattedStatus = status.replace(/_/g, " ").toLowerCase();

  const urlParms = `uid=${pkg.uid}&disclosureId=${pkg.analysis.disclosure_id}&type=${componentType}`

  return (
    <td key={colIndex} className={`admin-analysis__cell ${adminCellClass}`}>
      <div className="admin-analysis__status">
        <span className="admin-analysis__status-text">
          {status === ComponentAnalysisStatus.COMPLETE
            ? "Complete"
            : formattedStatus}
        </span>
      </div>

      <div className="admin-analysis__cell-actions">
        <div className="cell-buttons cell-buttons--trad-ocr">
          <ActionButton
            onClick={() => {
              const tradOCRParam = `${urlParms}&ocr-type=${API.OCRType.TRADITIONAL}`;
              navigate(`/admin/benchmarking/view-ocr?${tradOCRParam}`);
            }}
            disabled={isButtonDisabled}
            icon={ViewCellTradOCR}
            alt="View Trad OCR"
          />

          <ActionButton
            onClick={async () => alert("Not implemented yet.")}
            disabled={isButtonDisabled}
            icon={ResetCellTradOCR}
            alt="Reset Trad OCR"
          />
        </div>

        <div className="cell-buttons cell-buttons--form-ocr">
          <ActionButton
            onClick={() => {
              const formOCRParam = `${urlParms}&ocr-type=${API.OCRType.FORM}`
              navigate(`/admin/benchmarking/view-ocr?${formOCRParam}`);
            }}
            disabled={isButtonDisabled}
            icon={ViewCellFormOCR}
            alt="View Form OCR"
          />

          <ActionButton
            onClick={async () => alert("Not implemented yet.")}
            disabled={isButtonDisabled}
            icon={ResetCellFormOCR}
            alt="Reset Form OCR"
          />
        </div>

        <div className="cell-buttons cell-buttons--html-ocr">
          <ActionButton
            onClick={() => {
              const HTMLOCRParam = `${urlParms}&ocr-type=${API.OCRType.HTML}`
              navigate(`/admin/benchmarking/view-ocr?${HTMLOCRParam}`);
            }}
            disabled={isButtonDisabled}
            icon={ViewCellHTMLOCR}
            alt="View HTML OCR"
          />

          <ActionButton
            onClick={async () => alert("Not implemented yet.")}
            disabled={isButtonDisabled}
            icon={ResetCellHTMLOCR}
            alt="Reset HTML OCR"
          />
        </div>

        <ActionButton
          onClick={async () => {
            await API.analyzeComponent(pkg.uid, pkg.id, componentType);
          }}
          disabled={isButtonDisabled}
          icon={Reanalyze}
          alt="Analyze"
        />

        <ActionButton
          onClick={() => {
            navigate(
              `/admin/benchmarking/score?disclosure=${pkg.id}&type=${componentType}`
            );
          }}
          disabled={isButtonDisabled}
          icon={ViewScore}
          alt="View Score Package"
        />
      </div>
    </td>
  );
};
