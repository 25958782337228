import React, { useEffect, useState } from "react";

import {
  userIsAdminRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../Requirements";
import { useSearchParams } from "react-router-dom";

import { OCRType } from "../BenchmarkingDashboard/API";
import ComponentAnalysisType, {
  ComponentTypeDisplayMapping,
} from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import {
  viewFormOCR,
  viewTradOCR,
  viewHTMLOCR,
} from "../BenchmarkingDashboard/API";

import "./view_ocr.css";
import PDFViewer from "../DisclosureAnalysis/DisclosureViewer/PDFViewer";
import { PDFFile } from "../../../model/DisclosureAnalysis/PDFFile";
import { fetchPdfsForComponent } from "../DisclosureAnalysis/api/pdf";
import DOMPurify from "dompurify";

function ViewOCR(): JSX.Element {
  // Router hooks
  const [searchParams] = useSearchParams();

  // PDF Files
  const [pdfFilesToRender, setPdfFilesToRender] = useState<PDFFile[]>([]);

  // Management
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [OCRText, setOCRText] = useState<string | null>(null);
  const [htmlContent, setHtmlContent] = useState<string | null>(null);

  // Retrieve component type and disclosureID from url
  const uid = searchParams.get("uid") as string;
  const disclosureId = searchParams.get("disclosureId") as string;
  const componentType = searchParams.get("type") as ComponentAnalysisType;
  const ocrType = searchParams.get("ocr-type") as OCRType;

  useEffect(() => {
    setIsLoading(true);

    async function fetchPdfs() {
      try {
        const files = await fetchPdfsForComponent(
          uid,
          disclosureId,
          componentType
        );
        setPdfFilesToRender(files);
      } catch (err) {
        console.error("Failed to load PDFs", err);
      }
    }

    const fetchOCRText = async () => {
      try {
        if (ocrType === OCRType.FORM) {
          const response = await viewFormOCR(uid, disclosureId, componentType);
          if (response !== null) {
            setOCRText(response);
          }
          console.log(response);
        }

        if (ocrType === OCRType.TRADITIONAL) {
          const response = await viewTradOCR(uid, disclosureId, componentType);
          if (response !== null) {
            setOCRText(response);
          }
        }

        if (ocrType === OCRType.HTML) {
          // Add logic for HTML OCR handling when ready
          console.log("HTML OCR type handling is not implemented yet.");
          const response = await viewHTMLOCR(uid, disclosureId, componentType);
          if (response !== null) {
            const sanitizedHTML = DOMPurify.sanitize(response); 
            setHtmlContent(sanitizedHTML);
          }
        }

        if (OCRText?.length === 0) {
          setOCRText("OCR doesn't exist for this configuration.");
        }
      } catch (error) {
        console.error("Error fetching OCR text:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOCRText();
    fetchPdfs();
  }, [uid, disclosureId, componentType, ocrType, OCRText]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const formattedType = componentType.replace(/_/g, " ").toLowerCase();

  return (
    <div className="view-ocr">
      <header>
        <div className="view-ocr__title">OCR View</div>

        <div className="view-ocr__subtext">
          <h2 className="view-ocr__subtext--property">
            Propery: 123 Lane Street
          </h2>

          <h2 className="view-ocr__subtext--component-type">
            Document:
            {
              ComponentTypeDisplayMapping[
                formattedType.toUpperCase() as ComponentAnalysisType
              ]
            }
          </h2>

          <h2 className="view-ocr__subtext--ocr-type">Type: {ocrType}</h2>
        </div>
      </header>

      <section>
        <div className="view-ocr-content">
          <div className="view-ocr-text-container">
            {htmlContent ? (
              <div
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                className="view-ocr-html-content"
              />
            ) : (
              <pre className="view-ocr-text"> {OCRText} </pre>
            )}
          </div>

          <PDFViewer
            pdfs={pdfFilesToRender}
            selected={null}
            height={480}
            width={340}
          />
        </div>
      </section>
    </div>
  );
}

export default withRequirements(ViewOCR, [
  userSignedInRequirement,
  userIsAdminRequirement,
]);
