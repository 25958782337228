import { Routes, Route, Navigate } from "react-router-dom";
import Scroll from "./Scroll";

import { PrivateAnalysis, PublicAnalysis } from "../Pages/DisclosureAnalysis";
import DisclosureUpload from "../Pages/DisclosureUpload";
import FAQ from "../Pages/FAQ/FAQ";
import LogIn from "../Pages/Auth/LogIn";
import Paywall from "../Pages/Paywall";
import Signup from "../Pages/Auth/Signup";
import NotFound from "../Pages/NotFound";
import VerifyPhone from "../Pages/Auth/VerifyPhoneVerificationCode/VerifyPhoneVerificationCode";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { Landing } from "../Pages/Landing/Landing";
import TermsOfService from "../Pages/Legal/TermsOfServices";
import PrivacyPolicy from "../Pages/Legal/PrivacyPolicy";
import SendPhoneVerificationCode from "../Pages/Auth/SendPhoneVerificationCode/SendPhoneVerificationCode";
import ForgotUsername from "../Pages/Auth/ForgotUsername/ForgotUsername";
import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
import AdminPage from "../Pages/Admin/AdminPage";
import BenchmarkingDashboard from "../Pages/BenchmarkingDashboard/BenchmarkingDashboard";
import BenchmarkingScoring from "../Pages/BenchmarkingScoring/BenchmarkingScoring";
import ViewOCR from "../Pages/ViewOCR/ViewOCR";

function App() {
  return (
    <div>
      <Scroll />
      <Routes>
        {/* General */}
        <Route path="/" element={<Landing />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Disclosure Analysis */}
        <Route path="/disclosure/upload" element={<DisclosureUpload />} />
        <Route path="/disclosure/analysis" element={<PrivateAnalysis />} />
        <Route
          path="/disclosure/analysis/shared"
          element={<PublicAnalysis />}
        />

        {/* Account */}
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/send-phone-verification-code"
          element={<SendPhoneVerificationCode />}
        />
        <Route path="/verify-phone" element={<VerifyPhone />} />
        <Route path="/forgot-username" element={<ForgotUsername />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Admin and debugging tools */}
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/benchmarking" element={<BenchmarkingDashboard />} />
        <Route
          path="/admin/benchmarking/score"
          element={<BenchmarkingScoring />}
        />
        <Route path="/admin/benchmarking/view-ocr" element={<ViewOCR />} />

        {/* Subscriptions / Payments */}
        <Route path="/purchase" element={<Paywall />} />
        <Route path="/return" element={<Navigate to="/404" replace />} />

        {/* Void */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </div>
  );
}

export default App;
