import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  withRequirements,
  userSignedInRequirement,
  userIsAdminRequirement,
} from "../../Requirements";
import { getWithAuth } from "../../../firebase/authentication/auth";

/* use AdminPage.css */
import "./AdminPage.css";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function AdminPage(): JSX.Element {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getWithAuth(`${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/all`)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error("Error fetching user accounts:", error));
  }, []);

  return (
    <div className="admin-page">
      <button onClick={() => navigate("/admin/benchmarking")}>
        Benchmarking Dashboard
      </button>
      <h1>Admin Page</h1>
      {/* just display the json text for now*/}
      <pre>{JSON.stringify(users, null, 2)}</pre>
    </div>
  );
}

export default withRequirements(AdminPage, [
  userSignedInRequirement,
  userIsAdminRequirement,
]);
