import ComponentAnalysisType from "../AnalysisModules/ComponentAnalysisModule/Types";

export const chatSuggestionsByType: {
  [key in ComponentAnalysisType]: string[];
} = {
  [ComponentAnalysisType.TRANSFERDISCLOSURE]: [
    "What is the type of roof and general life expectancy?",
    "Are there kitchen appliances included with the sale?",
    "Any modifications without permits?",
  ],
  [ComponentAnalysisType.SELLERQUESTIONNAIRE]: [
    "What have the sellers noted as defected?",
    "What insurance claims has the property had?",
    "Who are the sellers?",
  ],
  [ComponentAnalysisType.ESD]: [
    "What have the sellers noted as defected?",
    "What insurance claims has the property had?",
    "Who are the sellers?",
  ],
  [ComponentAnalysisType.GENERALINSPECTION]: [
    "What are the top 5 immediate repair items, if any?",
    "Give me a timeline to complete these repairs.",
    "Approximate a repair cost.",
  ],
  [ComponentAnalysisType.TERMITEINSPECTION]: [
    "How long do you think it will take to complete these repairs?",
    "Has fumigation been recommended?",
    "How recent is this report?",
  ],
  [ComponentAnalysisType.HOAFINANCIALS]: [
    "What is the projected reserve funding like in the next 3 years?",
    "Were there any recent, major repairs in the community?",
    "Do you see any accounting issues?",
  ],
  [ComponentAnalysisType.HOARENTALRESTRICTIONS]: [
    "Is barbecueing permitted on my balcony or patio?",
    "Do you see any pet breed restrictions?",
    "Can I install solar panels on my unit?",
  ],
  [ComponentAnalysisType.NHD]: [
    "Are there any hazards that would disrupt my quality of life?",
    "How close is the nearest fault line?",
    "Would any of these factors affect resellability of the property?",
  ],
  [ComponentAnalysisType.PRELIMTITLEREPORT]: [
    "How much does the seller have left on their mortgage?",
    "Are there any title disputes between sellers?",
    "Am I dealing with individual sellers or an entity?",
  ],
  [ComponentAnalysisType.ROOFINSPECTION]: [
    "What is the remaining life on this roof?",
    "What do you identify as immediate repairs, if any?",
    "Are there any present leaks?",
  ],
  [ComponentAnalysisType.FOUNDATIONREPORT]: [
    "Is there any horizontal cracking of the structure?",
    "How long will these repairs take, if any?",
    "What are the top 5 recommended repairs, if any?",
  ],
  [ComponentAnalysisType.POOLINSPECTION]: [
    "Are there any present leaks in the pool?",
    "What is the overall health of the pool heater, if any?",
    "How long will these repairs take, if any?",
  ],

};
