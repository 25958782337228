import { useState } from "react";

import {
  GeneralInspectionProperty,
  GeneralInspectionAnalysis,
  GeneralInspectionIssue,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/GeneralInspection/GeneralInspection";
import arrowIcon from "../../../assets/SectionArrow.svg";
import {
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";

import "./GeneralInspection.css";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

export default function GeneralInspectionAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: GeneralInspectionAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  if (
    !analysis ||
    !analysis.sections ||
    Object.keys(analysis.sections).length === 0
  ) {
    return <div>Nothing to report.</div>;
  }

  const sortedSections = Object.entries(analysis.sections).sort(([a], [b]) => {
    const aIsOther = a.toLowerCase().includes("other");
    const bIsOther = b.toLowerCase().includes("other");
    if (aIsOther && !bIsOther) return 1;
    if (!aIsOther && bIsOther) return -1;
    return a.localeCompare(b);
  });

  return (
    <div>
      {sortedSections.map(([sectionName, section]) => (
        <div className="component-analysis-section" key={sectionName}>
          <div
            className="component-analysis-section-header"
            onClick={() => toggleSection(sectionName)}
          >
            <div>
              <img
                src={arrowIcon}
                alt="toggle"
                className={`component-analysis-section-header-arrow ${
                  expandedSections[sectionName] ? "expanded" : "collapsed"
                }`}
              />
              {sectionName}
            </div>
            <div className="component-analysis-section-header-brief">
              {(section.issues || []).length +
                (section.properties || []).length}{" "}
            </div>
          </div>
          {expandedSections[sectionName] && (
            <div>
              {section.properties && section.properties.length > 0 && (
                <div>
                  <div className="general-inspection-properties-list">
                    {section.properties.map(
                      (property: GeneralInspectionProperty, index: number) => (
                        <div
                          key={index}
                          className="general-inspection-property"
                          onClick={() =>
                            setSelectedPage({
                              fileID: property.reference?.pdf?.file_id,
                              page: property.reference?.page,
                              region: property.reference?.region,
                            } as SelectedPage)
                          }
                        >
                          <span className="general-inspection-property-name">
                            {property.name}
                          </span>
                          <span className="general-inspection-property-value">
                            {property.value}
                          </span>

                          <span style={{ marginLeft: "10px" }}>
                            <b className={`condition ${property.condition}`}>
                              {property.condition}
                            </b>
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {section.issues && (
                <div className="component-analysis-section-list">
                  {section.issues
                    .sort(
                      (a, b) =>
                        SeverityLevelOrder[a.severity] -
                        SeverityLevelOrder[b.severity]
                    )
                    .map((item: GeneralInspectionIssue, index: number) => (
                      <div
                        key={index}
                        className={`component-analysis-section-item ${
                          item.reference ? "has-reference" : ""
                        }`}
                        onClick={() => {
                          if (item.reference) {
                            setSelectedPage({
                              fileID: item.reference.pdf?.file_id,
                              page: item.reference.page,
                              region: item.reference.region,
                            } as SelectedPage);
                          }
                        }}
                      >
                        <div className="component-analysis-section-item-description bold">
                          {item.description}
                        </div>
                        <div className="component-analysis-section-item-recommendation">
                          <span style={{ fontWeight: "600" }}>
                            Recommendation:
                          </span>{" "}
                          {item.recommendation}
                        </div>
                        {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                          <div className={`severity-box ${item.severity}`}>
                            {item.severity}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
