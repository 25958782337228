import { PDFFile } from "../../../../model/DisclosureAnalysis/PDFFile";
import { PDFReferenceFirestore } from "../../../../model/DisclosureAnalysis/PDFReferenceFirestore";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const PDF_FETCH_HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

export async function fetchPdfFile(
  memoizedFile: PDFReferenceFirestore
): Promise<PDFFile> {
  const response = await fetch(
    `${PDF_FETCH_HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/pdf/${memoizedFile.uid}/${memoizedFile.disclosure_id}/${memoizedFile.file_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const { filename, data } = await response.json();

  if (!data) {
    throw new Error("No PDF content found");
  }

  // Decode the base64-encoded PDF bytes
  const pdfBytes = atob(data);
  const byteNumbers = new Array(pdfBytes.length);
  for (let i = 0; i < pdfBytes.length; i++) {
    byteNumbers[i] = pdfBytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const pdfBlob = new Blob([byteArray], { type: "application/pdf" });

  const pdfFile = {
    fileName: filename,
    data: pdfBlob,
    file_id: memoizedFile.file_id,
  };

  return pdfFile as PDFFile;
}

export interface PDFResponse {
  filename: string;
  data: string;
}

export async function fetchPdfsForComponent(
  uid: string,
  disclosureId: string,
  componentType: string
): Promise<PDFFile[]> {
  const response = await fetch(
    `${PDF_FETCH_HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/pdf/component/${uid}/${disclosureId}/${componentType}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResponse: { pdfs: PDFResponse[] } = await response.json();

  if (!jsonResponse.pdfs || !Array.isArray(jsonResponse.pdfs)) {
    throw new Error("No PDFs found for the component");
  }

  return jsonResponse.pdfs.map(({ filename, data }: PDFResponse) => {
    // Decode the base64-encoded PDF bytes
    const pdfBytes = atob(data);
    const byteNumbers = new Array(pdfBytes.length);
    for (let i = 0; i < pdfBytes.length; i++) {
      byteNumbers[i] = pdfBytes.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const pdfBlob = new Blob([byteArray], { type: "application/pdf" });

    return {
      fileName: filename,
      data: pdfBlob,
    } as PDFFile;
  });
}
