import ComponentAnalysisType from "../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { ComponentCellCorrectnessMap } from "./ComponentCell";

const BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const API_BASE_URL = `${HTTP_PROTOCAL}://${BASE_URL}/api/disclosure`;

// Enums
export enum ComponentBenchmarkingCorrectness {
  TBD = "TBD",
  CORRECT = "CORRECT",
  INCORRECT = "INCORRECT",
}

export enum OCRType {
  TRADITIONAL = "TRADITIONAL",
  FORM = "FORM",
  HTML = "HTML",
}

enum HTTPStatusCode {
  OK = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export interface ComponentBenchmarking {
  type: ComponentAnalysisType;
  correctness: ComponentBenchmarkingCorrectness;
  ground_truth: string;
  note: string;
}

export interface DisclosurePackageBenchmarking {
  components: { [key: string]: ComponentBenchmarking };
}

export interface DisclosureBenchmarkDataExistenceMap {
  [key: string]: boolean;
}

// -------------------------------------------------
// User Level Operations
// -------------------------------------------------
export async function userCheckPackagesExistence(
  uid: string
): Promise<DisclosureBenchmarkDataExistenceMap | null> {
  const url = `${API_BASE_URL}/benchmarking/user/check-data-existence/${uid}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      const data = await response.json();
      return data as DisclosureBenchmarkDataExistenceMap;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      return null;
    }
  } catch (error) {
    console.error(
      "Error occured while checking user packages existence:",
      error
    );
  }

  console.error("Unexpected error");
  return null;
}

export async function getUserBenchmarkDataScores(
  uid: string
): Promise<ComponentCellCorrectnessMap> {
  const url = `${API_BASE_URL}/benchmarking/user/data/${uid}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);
    let map: ComponentCellCorrectnessMap = {};

    if (response.status === HTTPStatusCode.OK) {
      const data = (await response.json()) as {
        [key: string]: DisclosurePackageBenchmarking | null;
      };
      for (const disclosureID in data) {
        const packageData = data[disclosureID];
        if (packageData === null) {
          continue;
        }
        for (const componentID in packageData.components) {
          const component = packageData.components[componentID];
          map[`${disclosureID}-${component.type}`] =
            packageData.components[componentID].correctness;
        }
      }
      return map;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      return {};
    }
  } catch (error) {
    console.error("Error occured while fetching user benchmark data:", error);
  }

  console.error("Unexpected error");
  return {};
}

// -------------------------------------------------
// Package Level Operations
// -------------------------------------------------

export async function checkPackageExistece(
  uid: string,
  disclosureId: string
): Promise<boolean | null> {
  const url = `${API_BASE_URL}/benchmarking/package/check-existence/${uid}/${disclosureId}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      const data = await response.json();
      return data.exists;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      return false;
    }
  } catch (error) {
    console.error("Error occured while checking package existence:", error);
  }

  console.error("Unexpected error");
  return null;
}

export async function createPackage(
  uid: string,
  disclosureId: string
): Promise<boolean> {
  const url = `${API_BASE_URL}/benchmarking/package/create`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Package created successfully.");
      return true;
    }

    if (response.status === HTTPStatusCode.BAD_REQUEST) {
      console.error(
        "Validation testing data already exists for this disclosure package"
      );
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclosure ID doesn't exist"
      );
    }
  } catch (error) {
    console.error("Error occurred creating testing data instance:", error);
  }

  return false;
}

export async function resetTradOCR(
  uid: string,
  disclosureId: string
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/package/reset-traditional-ocr`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Succesfully erased traditional OCR for all documents.");
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
    }
  } catch (error) {
    console.error(
      "Error occured erasing traditional OCR for all documents:",
      error
    );
  }
}

export async function resetFormOCR(
  uid: string,
  disclosureId: string
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/package/reset-form-ocr`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Succesfully erased form OCR for all documents.");
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
    }
  } catch (error) {
    console.error("Error occured erasing form OCR for all documents:", error);
  }
}

export async function analyzePackage(
  uid: string,
  disclosureId: string
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/package/analyze`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log(
        "Succesfully analyzed all document types in a given package."
      );
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
    }
  } catch (error) {
    console.error(
      "Error occured analyzing all document types in a given package:",
      error
    );
  }
}

// Component-Type Level Operations

export async function resetComponentTypeTradOCR(
  uid: string,
  componentType: ComponentAnalysisType
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component-type/reset-traditional-ocr`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("component_type", componentType);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log(
        "Succesfully erased traditional OCR for all files that classify under a certain document type."
      );
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error("User not found.");
    }
  } catch (error) {
    console.error(
      "Error occured erasing traditional OCR for all files that classify under a certain document type:",
      error
    );
  }
}

export async function resetComponentTypeFormOCR(
  uid: string,
  componentType: ComponentAnalysisType
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component-type/reset-form-ocr`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("component_type", componentType);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log(
        "Succesfully erased form OCR for all files that classify under a certain document type."
      );
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error("User not found.");
    }
  } catch (error) {
    console.error(
      "Error occured erasing form OCR for all files that classify under a certain document type:",
      error
    );
  }
}

export async function analyzeComponentType(
  uid: string,
  componentType: ComponentAnalysisType
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component-type/analyze`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("component_type", componentType);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log(
        "Succesfully analyzed all files that classify under a certain document type."
      );
    }
  } catch (error) {
    console.error(
      "Error occured analyzing all files that classify under a certain document type.",
      error
    );
  }
}

// Component Level Endpoints

export async function getGroundTruth(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<string | null> {
  const url = `${API_BASE_URL}/benchmarking/component/get-ground-truth/${uid}/${disclosureId}/${componentType}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      const data = await response.json();
      return data.ground_truth;
    }

    if (response.status === HTTPStatusCode.BAD_REQUEST) {
      console.error("Validation data for package is nonexistent.");
      return null;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
      return null;
    }
  } catch (error) {
    console.error("Error occurred while fetching ground truth:", error);
    return null;
  }
  console.error("Unexpected error.");
  return null;
}

export async function editGroundTruth(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType,
  groundTruth: string
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component/edit-ground-truth`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);
  formData.append("component_type", componentType);
  formData.append("ground_truth", groundTruth);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log(
        "Successfully edited the ground truth for a particular document."
      );
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Validation data for package is nonexistent or Disclosure with specified UID and disclousure ID doesn't exist."
      );
    }
  } catch (error) {
    console.error("Error occurred while fetching ground truth:", error);
  }
}

export async function getNotes(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<string | null> {
  const url = `${API_BASE_URL}/benchmarking/component/get-notes/${uid}/${disclosureId}/${componentType}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      const data = await response.json();
      return data.notes;
    }

    if (response.status === HTTPStatusCode.BAD_REQUEST) {
      console.error("Validation data for package is nonexistent.");
      return null;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
      return null;
    }
  } catch (error) {
    console.error("Error occurred while fetching notes:", error);
    return null;
  }
  console.error("Unexpected error.");
  return null;
}

export async function editNotes(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType,
  notes: string
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component/edit-notes`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);
  formData.append("component_type", componentType);
  formData.append("notes", notes);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Successfully edited the notes for a particular document.");
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Validation data for package is nonexistent or Disclosure with specified UID and disclousure ID doesn't exist."
      );
    }
  } catch (error) {
    console.error("Error occurred while fetching notes:", error);
  }
}

export async function getComponentBenchmarkingCorrectness(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<ComponentBenchmarkingCorrectness> {
  const url = `${API_BASE_URL}/benchmarking/component/correctness/${uid}/${disclosureId}/${componentType}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log(
        "Successfully received correctness for a particular document in a disclosure package."
      );
      const data = await response.json();
      return data.correctness as ComponentBenchmarkingCorrectness;
    }
  } catch (error) {
    console.error(
      "Error occurred retrieving correctness for a particular document in a disclosure package:",
      error
    );
    return ComponentBenchmarkingCorrectness.TBD;
  }

  return ComponentBenchmarkingCorrectness.TBD;
}

export async function setComponentBenchmarkingCorrectness(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType,
  value: ComponentBenchmarkingCorrectness
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component/set-correctness`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);
  formData.append("component_type", componentType);
  formData.append("value", value);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Successfully set cell correctness.");
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.log("Package doesn't exist.");
    }
  } catch (error) {
    console.error("Error occurred setting cell correctness:", error);
  }
}

export async function viewTradOCR(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<string | null> {
  const url = `${API_BASE_URL}/benchmarking/component/traditional-ocr/${uid}/${disclosureId}/${componentType}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Successfully recieved traditional OCR text.");
      const data = await response.json();
      return data.text;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
      return null;
    }
  } catch (error) {
    console.error("Error occurred while retrieving traditional OCR text.");
    return null;
  }

  return null;
}

export async function viewFormOCR(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<string | null> {
  const url = `${API_BASE_URL}/benchmarking/component/form-ocr/${uid}/${disclosureId}/${componentType}`;

  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Successfully recieved form OCR text.");
      const data = await response.json();
      return data.text;
    }

    if (response.status === HTTPStatusCode.NOT_FOUND) {
      console.error(
        "Disclosure with specified UID and disclousure ID doesn't exist."
      );
      return null;
    }
  } catch (error) {
    console.error("Error occurred while retrieving form OCR text.");
    return null;
  }

  return null;
}

export async function viewHTMLOCR(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<string | null> {
  const url = `${API_BASE_URL}/benchmarking/component/html-ocr/${uid}/${disclosureId}/${componentType}`;
  const options: RequestInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.OK) {
      console.log("Successfully recieved form OCR text.");
      const data = await response.json();
      return data.text;
    }

  } catch (error) {
    console.error("Error occurred while retrieving form OCR text.");
    return null;
  }

  return null;
}

export async function analyzeComponent(
  uid: string,
  disclosureId: string,
  componentType: ComponentAnalysisType
): Promise<void> {
  const url = `${API_BASE_URL}/benchmarking/component/analyze`;

  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("disclosure_id", disclosureId);
  formData.append("component_type", componentType);

  const options: RequestInit = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === HTTPStatusCode.BAD_REQUEST) {
      console.error("Cannot analyze component in this state/status.");
    }
  } catch (error) {
    console.error("Error occurred analyzing component:", error);
  }
}
